@import "../../../../variables";

.volume-slider {
  height: 100px;
  min-width: 30px;
  display: inline-block;
  margin: 0 0.5rem;
  text-align: center;
  font-size: $font-size-discreet;

  .value {
    font-weight: bold;
    color: $color-primary;
  }
  .name {
    color: $color-dark-text-secondary;
  }

  .rc-slider {
    margin: auto;
    height: calc(100% - 38px);
  }
}