@import "../../../variables";

$header-height: 40px;

.files {
  padding-top: 1rem;
  height: 100%;

  .container {
    height: 100%;
  }

  .header {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    height: $header-height;

    .title {
      font-size: 1.3rem;
      font-weight: 600;
      flex: 1 1 auto;
      font-family: $font-family-header;

      &.secondary {
        font-size: 1rem;

        #{$theme-dark} & {
          color: $color-dark-text-secondary;
        }

        #{$theme-light} & {
          color: $color-light-text-secondary;
        }
      }

      .subtitle {
        font-family: $font-family-base;
        font-size: $font-size-discreet;
        font-weight: normal;

        #{$theme-dark} & {
          color: $color-dark-text-secondary;
        }

        #{$theme-light} & {
          color: $color-light-text-secondary;
        }
      }
    }

    .display-options {
      display: flex;
      flex: 0 0 auto;
      button {
        margin-left: .5rem;
      }

      .search {
        border-radius: 15px;
        max-width: 150px;
        display: inline-block;
        margin-left: 0.5rem;
      }
    }
  }

  .content {
    height: calc(100% - #{$header-height});
    overflow-y: auto;
  }
}

.search {
  border-radius: 15px;
  max-width: 150px;
  display: inline-block;
}
