@import "../../../variables";

footer.aw-footer {
  position: fixed;
  padding: 0 $spacer / 2;
  z-index: 1002;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: $footer-height;
  background-color: $color-nav-bg;
  color: $color-nav-text-active;

  .btn.basic,
  .aw-icon {
    font-size: $font-size-discreet !important;
  }

  .aw-circular-badge {
    vertical-align: text-bottom;
  }

  .info {
    .aw-popup {

      font-size: $font-size-discreet;
      width: 300px;

      table {
        margin: 0 !important;
        font-weight: bold;
        color: $color-nav-text;
        vertical-align: bottom;

        .value {
          font-weight: normal;
          margin-left: 0.5rem;
          vertical-align: bottom;
          @include aw-theme(color, $color-dark-text-primary, $color-light-text-primary);
        }
      }
    }
  }

  .monitor {
    flex: 0 0 auto;

    .badge{
      display: inline-flex;
      align-items: center;
      height: 22px;
      margin-left: $spacer / 2;
      font-size: .75rem;
      font-weight: normal;

      color: $color-gray-02;
      background-color: $color-gray-05;
      &.aw-alert {
        color: white;
        background-color: $color-red !important;
      }

      &.aw-warning {
        color: $color-gray-05;
        background-color: $color-yellow-01 !important;
      }

      &.aw-activated {
        color: white;
        background-color: $color-primary !important;
      }
    }

    .aw-icon {
      margin-right: $spacer / 4;
    }

  }
}