@import "../../../../variables";

.hardware-spec {
  max-width: calc(100% - 0.5rem);
  font-size: $font-size-discreet;
  line-height: $font-size-discreet;
  margin-bottom: 1rem;

  ul {

    li {
      padding: 0.2rem 0;
    }
  }

  .label {
    font-weight: bold;
    margin-right: 0.5rem;

    #{$theme-dark} & {
      color: $color-dark-text-secondary;
    }

    #{$theme-light} & {
      color: $color-light-text-secondary;
    }

  }
}

.system-monitor {
  display: flex;
  justify-content: center;
  padding: 1rem 0;

  .section {
    margin: 0 2 * $spacer;
    text-align: center;
    font-size: 1.8rem;

    .title {
      .aw-icon {
        margin-right: 0.5rem;
        vertical-align: middle;
      }
    }

    .value {
      font-size: 45px;
    }

    &.aw-alert {
      color: $color-error !important;
    }

    &.aw-warning {
      color: $color-warning !important;
    }
  }
}

.confirm-text {
  font-size: 1.2rem;
  margin-bottom: 1rem;

  .indicator {
    font-size: $font-size-discreet;

    #{$theme-dark} & {
      color: $color-dark-text-secondary;
    }

    #{$theme-light} & {
      color: $color-light-text-secondary;
    }

  }
}

.file-name {
  margin-left: 1rem;
  font-size: 1rem;
}

.system-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1rem;
}

.encoding-capabilities {
  padding: 0.5rem;
  border-radius: 0.2rem;

  #{$theme-dark} & {
    background-color: $color-dark-bg-box;
    border: 1px solid $color-dark-border-box;
  }

  #{$theme-light} & {
    background-color: $color-light-bg-box;
    border: 1px solid $color-light-border-box;
  }


  .title {
    font-size: 1.2rem;
    font-weight: 700;
    font-family: $font-family-header;

    #{$theme-dark} & {
      color: $color-dark-text-primary;
    }

    #{$theme-light} & {
      color: $color-light-text-primary;
    }
  }

  .subtitle {
    font-size: $font-size-discreet;
    font-weight: normal;
    margin-bottom: 1rem;

    #{$theme-dark} & {
      color: $color-dark-text-secondary;
    }

    #{$theme-light} & {
      color: $color-light-text-secondary;
    }
  }

  .aw-table {

    th {
      font-size: $font-size-discreet;
    }
  }

}