@import "../../../../../variables";
.aw-input {

  .header .metadata > * {
    margin: 0 .2rem;
  }

  .content-title {
    .aw-icon {
      font-size: 14px;
      vertical-align: top;
      +.aw-icon {
        margin-left: calc(#{$spacer} / 4);
      }
    }
  }

  .thumbnail .thumb-error {
    text-align: center;
    font-size: 14px;
  }

  .bitrate-indicator {
    color: $color-dark-text-primary;
    font-size: 1.5rem;
  }

  .bitrate-badge {
    color: $color-dark-text-primary;
    background-color: rgba($color-gray-05, .8);
    border-color: rgba($color-gray-05, .8);
  }

  .live-timer {
    color: $color-dark-text-primary;
    font-size: $font-size-base;
    line-height: 1.5rem;
    align-self: baseline;
  }

  .battery-level {
    flex: 0 1 auto;
    margin-left: 0.5rem;
    display: flex;
    font-size: $font-size-discreet;
  }

  .aw-control-grid.inline &.aw-control-grid-item.collapsed {
    .header .controls {
      width: 100%;
    }
    .content .main-content .compact {
      padding-left: $spacer;
    }
  }

  .playback {
    .thumbnail:hover .progress {
      height: 7px;
    }

    .select > button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.aw-control-grid-item .main-content {
    .compact .actions {
      button + .copy-to-clipboard {
        margin-left: 6px;
      }
      .copy-to-clipboard + button {
        margin-left: 6px;
      }
    }
    
    .details {
      margin-bottom: .25rem;
    }
  }

  .additional-content {
    .rounded {
      border-radius: 8px !important;
    }
  }

  $module-padding: 1rem 0 0 0;

  .live-info {
    flex: 0 0 auto;
    text-align: center;

    .row {
      line-height: 1;
    }

    dl {
      @include aw-theme(background-color, $color-dark-bg-box, $color-light-bg-box);
      margin-bottom: 0;
      padding: .5rem 0;
    }

    dt {
      text-align: right;
      font-weight: normal;

      #{$theme-dark} & {
        color: $color-dark-text-secondary;
      }

      #{$theme-light} & {
        color: $color-light-text-secondary;
      }

    }
    dd {
      text-align: left;
    }

    dt,
    dd {
      padding: 0 0.3rem;
    }
  }


  .settings-controls {
    padding: $module-padding;
    .title {
      text-align: center;
    }

    .controls {
      flex: 0 0 auto;
      @include aw-theme(background-color, $color-dark-bg-box, $color-light-bg-box);

      .latency-controls, .bitrate-controls {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: .5rem;
        font-size: 0.9rem;

        .manual {
          flex: 1 0 auto;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          button {
            margin: 0
          }
        }
      }

      .max-value,
      .min-value {
        font-size: $font-size-discreet;

        #{$theme-dark} & {
          color: $color-dark-text-secondary;
        }

        #{$theme-light} & {
          color: $color-light-text-secondary;
        }
      }

      .max-value {
        float: right;
      }
    }
  }

  .stream-info {
    padding: $module-padding;

    .title {
      text-align: center;

      button {
        float: right;
      }
    }

    table {

      @include aw-theme(background-color, $color-dark-bg-box, $color-light-bg-box);
      margin-bottom: 0;
      margin-top: 0;
      text-align: center;
      tbody th {
        font-weight: 600;
        font-size: $font-size-discreet;
        text-align: right;

        #{$theme-dark} & {
          color: $color-dark-text-secondary;
        }

        #{$theme-light} & {
          color: $color-light-text-secondary;
        }
      }

      td.bitrate {
        min-width: 80px;
      }
    }
  }

  .btn-group > .btn:not(:last-child):not(.dropdown-toggle).remote-profiles-toggle {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  .network-info {
    .title {
      text-align: center;
    }
    .content {
      @include aw-theme(background-color, $color-dark-bg-box, $color-light-bg-box);
      padding: .5rem;
    }
    .controls {
      display: flex;
      button {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
      .aw-circular-badge {
        padding: 0.4rem;
      }

      .interface-toggle + .interface-toggle {
        margin-left: 1.5rem;
      }
    }
    .supsub-block {
      position: absolute;
    }
    .supsub-block sup {
      display:block; position:relative; left:0px; top: 0.4rem
    }
    .supsub-block sub {
      display:block; position:relative; left:0px; top: 1.1rem
    }
    .aw-icon {
      font-size: 1.4em
    }
    .subtitle {
      font-size: $font-size-discreet;
    }
    table {
      margin-bottom: 0;
      th {
        font-weight: 600;
        font-size: $font-size-discreet;
        text-align: center;

        #{$theme-dark} & {
          color: $color-dark-text-secondary;
        }

        #{$theme-light} & {
          color: $color-light-text-secondary;
        }

      }
      td {
        text-align: center;
        font-size: $font-size-discreet;
      }

      td.bitrate {
        min-width: 80px;
      }
    }
  }

  .meta-data {
    min-width: 200px;
    font-size: 0.8rem;
    dt {
      margin-left: .5rem;
    }
    dd {
      margin-left: 1rem;
    }
  }

  .aw-loader .scale-loader [class*="bar"] {
    height: 32px;
  }
}