@import "../../../variables";
@import "./input-section/input/index";
@import "input-section/multi-view/index";
@import "./file-section/index";
@import "./input-section/ip-input-profiles-settings/index";
@import "./output-section/ip-output-profiles-settings/index";
@import "./encoder-section/index";
@import "./encoder-section/encoder-profiles-settings/index";
@import "node_modules/@aviwest/ui-kit/dist/styles/index.scss";

$header-height: 50px;
$limits-width: 2px;
$header-secondary-height: 40px;

.dashboard {
  height: 100%;

  &.mobile {
    overflow-y: scroll;
    .layout-section {
      height: auto;
      .section-content{
        height: auto;
      }
    }
  }
}

button.link:hover,
button.link {
  background: none;
  background-color: transparent !important;
  padding: 0;
  color: $color-orange-01 !important;
}

.navbar.aw-navbar + .content {
  height: 100vh;
  padding: $spacer;
  padding-top: $navbar-height;
}

.flex-horizontal {
  display: flex;
  height: 100%;
  flex-direction: row;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
}

.layout-section {
  height: 100%;
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;

  .section-header {
    color: $color-gray-02;
    height: $header-height;
    margin: 0 0.625rem;
    display: flex;
    align-items: center;

    .display-options {
      flex: 0 0 auto;
      display: flex;
    }

    > .title button.link,
    > .title {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      font-family: $font-family-header;
      font-size: 1.5rem;
      text-transform: uppercase;
    }
  }

  .section-content {
    height: calc(100% - #{$header-height});
    padding: calc(#{$grid-cell-padding} / 2);
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    .hardware,
    .ip {
      display: flex;
      flex: auto;
      flex-wrap: wrap;
      align-content: flex-start;
    }

    .splitter {
      height: $limits-width;
      margin: 0.5rem calc(0.5rem / 2);
      flex: 1 1 100%;

      #{$theme-dark} & {
        background-color: $color-dark-delimiter;
      }
      #{$theme-light} & {
        background-color: $color-light-delimiter;
      }

    }
  }

  &.hidden {
    display: none;
  }
}

.header {

  .source,
  .destination {
    display: inline-block;
    margin-left: 0.2rem;
    transition: color 0.1s linear;

    #{$theme-dark} & {
      color: $color-dark-text-secondary;
    }

    #{$theme-light} & {
      color: $color-light-text-secondary;
    }

    .type {
      font-size: $font-size-discreet;
      margin-right: 0.2rem;
      margin-left: 0.2rem;
    }

    .type:last-child {
      margin-right: 0;
    }

    .aw-icon {
      font-size: 1rem;
      vertical-align: middle;
    }

    &.selectable {
      cursor: pointer;
    }

    &.selectable:hover {
      color: $color-primary;
    }
  }
}


$thumb-width: calc(#{$grid-cell-height} - 2 * #{$grid-cell-padding} - 2 * #{$grid-cell-border});
$thumb-height: calc(#{$thumb-width} * 9 / 16);
$audioscale-legend-font-size: .7rem;

.aw-control-grid-item {
  .aw-control-grid.grid & .placeholder svg.aw-icon {
    font-size: 2.5rem;
  }

  .header {
    z-index: 1002;
    .controls > .btn {
      z-index: 1031;
    }
  }

  .details {
    .audio-levels {
      flex: 0 0 auto;
      padding: 10px 0;

      .audio-scale {
        width: 40px;
        display: inline-block;
        height: 100%;
        margin: 0 2px;
        color: $color-gray-02;

        >.scale {
          height: 100%;
          width: $vu-meter-width;
          position: relative;
          border: 1px solid $color-gray-02;
          border-right: none;

          .scale-danger {
            height: 12%;
            border-bottom: 1px solid $color-gray-02;
            position: relative;
          }

          .scale-warning {
            height: 24%;
            border-bottom: 1px solid $color-gray-02;
            position: relative;
          }

          span {
            font-size: $audioscale-legend-font-size;
            line-height: $audioscale-legend-font-size;
            position: absolute;
            left: 8px;
            white-space: nowrap;
            text-align: left;
          }

          .scale-min span {
              top: calc(-#{$audioscale-legend-font-size} / 2);
            }

          .scale-max, .scale-danger, .scale-warning {
            span {
              bottom: calc(-#{$audioscale-legend-font-size} / 2);
            }
          }
        }
      }
    }
    .audio-levels.intercom {
      margin-bottom: 2.75rem;
      padding-top: 0;
      .vu-meter {
        margin: 0 8px;
      }
    }
    .audio-header {
      margin-bottom: .5rem;
      color: $color-gray-02;
      font-size: $audioscale-legend-font-size;
      line-height: $audioscale-legend-font-size;
      text-align: left;
    }
    .audio-controls {
      text-align: left;
      .aw-icon {
        margin: 0 4px;
        cursor:pointer;
      }
    }

    .graph-container {
      flex: 1 1 auto;

      .resize-triggers .contract-trigger:before {
        // Fix for chromium based browsers where contract-trigger causes drag preview (when dragging item) to be bigger than item
        // Width is set to 110% instead of 200% in order to prevent contract-trigger to overflow on left
        // In our case vertical resizing is not used so height: 100% is fine
        width: 110%;
        height: 100%;
      }
    }
  }

  &.aw-target-valid {
    box-shadow: 0 0 0 1px $color-primary;
  }

  &.aw-target-invalid {
    opacity: 0.5;
  }

  .thumb {
    height: $thumb-height;

    #{$theme-dark} & {
      background: $color-dark-border-box;
    }

    #{$theme-light} & {
      background: $color-light-border-box;
    }

    .thumb-info {
      position: absolute;
      right: 0;
      top: 0;
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      margin: 0.2rem;
      padding: 0.2rem 0 0.2rem 0.2rem;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 0.2rem;
      .aw-icon {
        margin-right: 0.2rem;
      }
    }

    &:hover {
      .thumb-info {
        top: 12px;
      }
    }
  }

  .forward-info {
    text-align: center;
    height: $thumb-height;
    font-size: $font-size-discreet;
    position: relative;

    .forward-info-status {
      position: absolute;
      right: 2.4rem;
      top: 0;
      font-size: 1.2rem;
    }
  }

  .forward-detail {
    margin-top: 0.8rem;

    .filename {
      color: $color-dark-text-primary;
    }

    .filesize {
      #{$theme-dark} & {
        color: $color-dark-text-secondary;
      }

      #{$theme-light} & {
        color: $color-light-text-secondary;
      }

    }
  }

  .graph {
    position: relative;

    .overlay {
      position: absolute;
      top: 0;
      width: 100%;
    }

    .rv-discrete-color-legend-item {
      color: white
    }
  }
}

.gutter {
  display: flex;
  overflow: hidden;

  .separator {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    #{$theme-dark} & {
      background-color: $color-dark-delimiter;
    }
    #{$theme-light} & {
      background-color: $color-light-delimiter;
    }

    .handle {
      background-color: $color-gray-05;
      border-radius: 14px;
      color: $color-gray-03;
      font-size: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.gutter-horizontal {
    flex-direction: row;
    justify-content: center;
    cursor: col-resize;

    .separator {
      width: $limits-width;
      height: 100%;

      .aw-icon {
        padding-left: 1px;
      }
    }

    .handle {
      height: 48px;
      width: 14px;
      position: absolute;
      top: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.gutter-vertical {
    flex-direction: column;
    justify-content: center;
    cursor: row-resize;

    .separator {
      width: 100%;
      height: $limits-width;

      .aw-icon {
        padding-top: 1px;
      }
    }

    .handle {
      height: 14px;
      margin: 0 auto;
      width: 48px;
    }
  }

}
